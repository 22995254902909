import { cdnApi } from '@mk/services'
import { PlatformCompProps } from '@mk/widgets-bridge-sdk'
import React from 'react'

interface Props {
  src: string
}
const padding = 20

const PosterWM: React.FC<PlatformCompProps & Props> = (props) => {
  const { containerInfo, src } = props
  const { width } = containerInfo
  const { height } = containerInfo

  return (
    <div className="poster_watermark">
      <svg viewBox={`${-padding} ${-padding} ${width + padding * 2} ${height + padding * 2}`}
        width={width} height={height}>
        <image xlinkHref={src}
          width={width} height={height} />
      </svg>
    </div>
  )
}

export default PosterWM