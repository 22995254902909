import { EventEmitter, } from '@mk/utils'
import { MaskData, } from "./types"


export interface GlobalInfo {
    mdata: MaskData['data'] | null
    preCompDict: Record<string, boolean>
    maskIds: string[]
}

const globalInfo: GlobalInfo = {
    mdata: null,
    preCompDict: {},
    maskIds: []
}

let mData: MaskData['data'] | null
let preCompDict: Record<string, boolean> = {}
let maskIds: string[] = []

Object.defineProperties(globalInfo, {
    mdata: {
        get() {
            return mData
        },
        set(md: MaskData['data'] | null) {
            mData = md
            EventEmitter.emit('mdataChange', md)
        }
    },
    preCompDict: {
        get() {
            return preCompDict
        },
        set(val: Record<string, boolean>) {
            preCompDict = val
            EventEmitter.emit('preCompDictChange', val)
        }
    },
    maskIds: {
        get() {
            return maskIds
        },
        set(val: string[]) {
            maskIds = val
            EventEmitter.emit('maskIdsChange', maskIds)
        }
    }
})

export * from './CanvasMask'
