import React, { useEffect, useState } from "react"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import { queryToObj } from "@mk/utils"
import MkWatermarkV2 from "./MkWatermarkV2"
import { CanvasMaskWatermark as MkWatermarkV1 } from "./MaskV1"

/**
 * props 由自定义的 form 表单传入
 */
interface MkWatermarkProps { }

const MkWatermark: React.FC<PlatformCompProps<MkWatermarkProps>> = (props) => {
  const { editorSDK, viewerSDK, worksType = '', lifecycle: { didLoaded, didMount } } = props
  const queryParams = queryToObj()
  const isH5 = ['h5', 'longh5'].includes(worksType.toLowerCase())
  let hasWatermark = true
  let watermarkVersion = '1'

  if (queryParams.__watermark__ === 'mk_false') {
    hasWatermark = false
  } else if (queryParams.mode === 'template') {
    hasWatermark = false
  } else if (editorSDK) {
    hasWatermark = editorSDK.hasWatermark()
    watermarkVersion = editorSDK.watermarkVersion?.()
  } else if (viewerSDK) {
    hasWatermark = viewerSDK.hasWatermark()
    watermarkVersion = viewerSDK.watermarkVersion?.()
  }

  const useMask2 = isH5 || !!queryToObj().watermark_v2 || watermarkVersion === '2'

  useEffect(() => {
    if (!hasWatermark) {
      /** 如果没有水印，则 didMount，有水印的情况下交给水印逻辑组件实现 */
      didMount({ boxInfo: { width: 0, height: 0 } })
      didLoaded()
    }
  }, [])


  useEffect(() => {
    if ((!hasWatermark || !useMask2) && !document.body.classList.contains('__disabled_watermark_operator__')) {
      document.body.classList.add('__disabled_watermark_operator__')
    }

    return () => {
    }
  }, [])

  if (!hasWatermark) return <></>

  return useMask2 ? (
    <MkWatermarkV2 {...props} />
  ) : (
    <MkWatermarkV1 {...props} />
  )
}

export default MkWatermark
