import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import clas from "classnames"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import { cdnApi } from "@mk/services"

import "./index.scss"
import H5WM from "./h5wm"
import PosterWM from "./posterWM"

/**
 * props 由自定义的 form 表单传入
 */
interface MkWatermarkProps { }

const watermarkMap = {
  poster: cdnApi('/cdn/editor7/watermark/poster_watermark_2.svg'),
  h5: cdnApi('/cdn/mk-widgets/water-mark/1_1.gif'),
}

const getWatermarkCorrectPosition = ({ canvaInfo, viewerInteractive, pageInfo }) => {
  const watermarkW = Math.min(canvaInfo.canvaW, canvaInfo.canvaH) / 5
  const watermarkH = (viewerInteractive ? watermarkW * 2 : watermarkW)
  const ratio = watermarkW / 375
  const offsetY = (viewerInteractive ? 300 : 50) * ratio
  const nextX = canvaInfo.canvaW - watermarkW - 12 * ratio
  const nextY = (pageInfo ? pageInfo.height : canvaInfo.canvaH) - watermarkH - offsetY

  return {
    watermarkW,
    watermarkH,
    nextX,
    nextY,
  }
}

const MkWatermarkV2: React.FC<PlatformCompProps<MkWatermarkProps>> = (props) => {
  const {
    pageInfo,
    editorSDK, canvaInfo, viewerSDK, worksType = '', containerInfo,
    lifecycle: { didLoaded, didMount }
  } = props

  const [mount, setMount] = useState(false)
  const viewerInteractive = ['h5', 'longh5'].includes(worksType.toLowerCase())
  const watermarkType = !viewerInteractive ? 'poster' : 'h5'
  const showViewerMode = /\/h5\/|\/longH5\/|\/poster\//.test(window.location.href) || (!viewerInteractive && /\/apppreview\//.test(window.location.href)) || !/\/pcviewer\//.test(window.location.href)

  useEffect(() => {
    const init = async () => {
      const _didMount = () => {
        const { nextX, nextY, watermarkH, watermarkW } = getWatermarkCorrectPosition({ canvaInfo, viewerInteractive, pageInfo })
        /** 修正位置 */
        if (
          (nextX).toFixed(0) !== (containerInfo.x).toFixed(0) ||
          (nextY).toFixed(0) !== (containerInfo.y).toFixed(0) ||
          (watermarkH).toFixed(0) !== (containerInfo.height).toFixed(0) ||
          (watermarkW).toFixed(0) !== (containerInfo.width).toFixed(0)
        ) {
          editorSDK?.changeContainer({
            x: nextX,
            y: nextY,
            width: watermarkW,
            height: watermarkH
          })
        }

        didMount({
          boxInfo: {
            x: nextX,
            y: nextY,
            width: watermarkW,
            height: watermarkW,
          }, data: {
            version: '1.0'
          }
        })
      }

      const IMG = new Image()
      IMG.src = watermarkMap[watermarkType]
      IMG.onload = async () => {
        setMount(true)
        _didMount()
        didLoaded()
      }
    }
    init()
    return () => {
    }
  }, [])

  useEffect(() => {
    if (editorSDK && pageInfo) {
      const { nextY } = getWatermarkCorrectPosition({ canvaInfo, viewerInteractive, pageInfo })
      editorSDK?.changeContainer({
        y: nextY,
      })
    }
  }, [pageInfo])


  if (!mount) return <></>

  if (viewerSDK) {
    if (!showViewerMode) return <></>

    if (viewerInteractive) {
      return ReactDOM.createPortal((
        <div className={clas("mk_watermark_component", viewerSDK && 'viewer_watermark')}>
          <H5WM {...props} />
        </div>
      ), document.body)
    } else {
      return ReactDOM.createPortal((
        <div className={clas("mk_watermark_component", viewerSDK && 'viewer_watermark')}>
          <PosterWM {...props} src={watermarkMap[watermarkType]} />
        </div>
      ), document.querySelector('#id-canvas .canvas_content') || document.body)
    }
  }

  if (editorSDK) {
    if (viewerInteractive) {
      return <H5WM {...props} />
    } else {
      return <PosterWM {...props} src={watermarkMap[watermarkType]} />
    }
  }

  return <></>
}

export default MkWatermarkV2
