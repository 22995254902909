import { cdnApi, getWorksDetailStatic } from '@mk/services'
import { isMakaAppClient } from '@mk/utils'
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import React from 'react'

const getVW = () => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  return vw
}

const getCanvasScale = () => {
  const canvaW = +getComputedStyle(document.documentElement)
    .getPropertyValue('--canvas-w').replace('px', '')
  const vw = getVW()
  const scale = vw / canvaW
  return scale
}

interface Props {
}

export default class H5WM extends React.PureComponent<PlatformCompProps & Props, {
  showWatermark: boolean
}> {
  state = {
    showWatermark: !getWorksDetailStatic().template_id
  }

  wmRef = React.createRef<HTMLImageElement>()

  componentDidMount(): void {
    const { viewerSDK } = this.props
    console.log('handleWatermarkEvent')

    /** show 埋点 */
    viewerSDK?.handleWatermarkEvent?.('show')
  }

  setShowWatermark = (showWatermark) => {
    this.setState({
      showWatermark
    })
  }

  handleClick = () => {
    const { viewerSDK } = this.props
    if (viewerSDK) {
      viewerSDK.handleWatermarkEvent('click')
      if (isMakaAppClient()) {
        const queryObj = {
          workId: viewerSDK.workInfo?.getWorksID?.() || '',
          isVideo: "0",
          hideExport: "1",
          forward_page_name: 'maka',
        }
        const queryStr = new URLSearchParams(queryObj).toString()
        viewerSDK.appBridge?.navToPage({
          url: `maka://materialShoppingList?${queryStr}`,
          type: "NATIVE",
        })
      }
    }
  }

  render() {
    const { viewerSDK } = this.props
    const { showWatermark } = this.state
    const canvasScale = viewerSDK ? getCanvasScale() : 1
    const h5WatermarkW = viewerSDK ? `${(+(getVW() / 6).toFixed(0))}px` : '100%'

    return showWatermark ? (
      <div className="h5_watermark" style={{ width: h5WatermarkW }}>
        <div onClick={this.handleClick}>
          <img src={cdnApi('/cdn/mk-widgets/water-mark/1_1.gif')} style={{ width: '100%' }} ref={this.wmRef} alt='' />
        </div>
        <div className="watermark_close_btn"
          style={{
            transform: `scale(${canvasScale})`
          }}
          onClick={() => {
            if (viewerSDK) {
              this.setShowWatermark(false)
            }
          }}>
          <img
            src={cdnApi("/cdn/mk-widgets/water-mark/close.png", { resizeWidth: 24 })} alt=""
          />
        </div>
      </div>
    ) : <></>
  }
}