import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk"
import './index.scss'
import { cdnApi } from "@mk/services"

interface Props {
}

export const CanvasMaskWatermark: React.FC<PlatformCompProps<Props>> = (props) => {
    const { lifecycle: { didLoaded, didMount }, viewerSDK } = props
    const [mount, setMount] = useState(false)

    useEffect(() => {
        const init = async () => {
            didMount({
                boxInfo: { width: 0, height: 0 }
            })
            didLoaded?.()
            setMount(true)
        }
        init()
        return () => {
        }
    }, [])

    const wmDOM = (
        <div className={`wm_container`}
            style={{
                background: `url("${cdnApi("/cdn/mk-widgets/sdk/canvas-watermark.svg")}") repeat 0 0/100%`
            }}>
            <div className="poster-editor-small-mark" style={{
                background: `url("${cdnApi("/cdn/mk-widgets/sdk/watermark_web_mark.png")}") no-repeat center center`
            }}>
            </div>
        </div>
    )

    const canvasContainerDOM = viewerSDK ?
        document.querySelector<HTMLDivElement>('.fixed_layers_container')
        : document.querySelector<HTMLDivElement>('#id-canvas .canvas_content')

    return mount && canvasContainerDOM ? (
        ReactDOM.createPortal(
            wmDOM,
            canvasContainerDOM
        )
    ) : <></>
}
